import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Form, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { setFormErrors } from 'helpers/errors';
import { createStore } from 'helpers/requests/stores';
import { getPostgreses } from 'helpers/requests/databases';
import { appPaths } from 'config';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const CreateStoreForm = () => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const [postgreses, setPostgreses] = useState([]);
  const [selectedPostgres, setSelectedPostgres] = useState(null);
  const [postgresesAreLoaded, setPostgresesAreLoaded] = useState(false);
  const isMounted = useRef(true);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError
  } = useForm();
  const navigate = useNavigate();

  let handleGetPostgreses = async () => {
    const r = await getPostgreses();
    if (r.success) {
      const returnedPostgreses = r.response.data;
      const firstOption = returnedPostgreses.length
        ? returnedPostgreses[0]
        : null;
      setSelectedPostgres(firstOption);
      if (firstOption !== null) {
        setValue('postgres_id', firstOption.id);
      }
      setPostgreses(returnedPostgreses);
      setPostgresesAreLoaded(true);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };
  const reloadPostgreses = async () => {
    if (postgresesAreLoaded) {
      setPostgresesAreLoaded(false);
      await handleGetPostgreses();
    }
  };

  useEffect(() => {
    handleGetPostgreses();
  }, []);

  const submitForm = async data => {
    setSubmittingForm(true);
    const r = await createStore(data.name, data.postgres_id);
    if (r.success) {
      if (isMounted.current) {
        navigate(appPaths.stores.detail.replace(':id', r.response.data.id));
      }
      toast.success('Store created successfully.');
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Form noValidate onSubmit={handleSubmit(submitForm)}>
      <Card className="mb-3 bg-light">
        <Card.Body>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} md={6} sm={12}>
              <Form.Label>Name*</Form.Label>
              <Form.Control
                placeholder="Name"
                name="name"
                type="text"
                isInvalid={!!errors.name}
                {...register('name', {
                  required: 'Enter name'
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3 bg-light">
        <Card.Body>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} md={6} sm={12}>
              <div className="d-flex justify-content-between align-items-center">
                <Form.Label>Database*</Form.Label>
                {postgresesAreLoaded && (
                  <Button
                    variant="link"
                    className="p-0 link-secondary"
                    onClick={reloadPostgreses}
                  >
                    <small>Reload</small>
                  </Button>
                )}
                {!postgresesAreLoaded && (
                  <Button variant="link" className="p-0 link-secondary">
                    <small>Loading...</small>
                  </Button>
                )}
              </div>
              <Form.Select
                name="postgres_id"
                isInvalid={!!errors.postgres_id}
                {...register('postgres_id', {
                  required: 'Choose a database'
                })}
                disabled={!postgresesAreLoaded}
              >
                {/* selectedPostgres must be first. Otherwise, what react hook form
                  deems selected and and what the user thinks is selected may differ. */}
                {selectedPostgres !== null && (
                  <option key={selectedPostgres.id} value={selectedPostgres.id}>
                    {selectedPostgres.name}
                  </option>
                )}
                {postgreses
                  .filter(
                    postgres =>
                      selectedPostgres === null ||
                      postgres.id !== selectedPostgres.id
                  )
                  .map(postgres => (
                    <option key={postgres.id} value={postgres.id}>
                      {postgres.name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.postgres_id?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>

      {errors.root?.nonFieldError?.message && (
        <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
      )}

      <Card>
        <Card.Body>
          <Form.Group className="d-flex justify-content-end">
            <Button type="submit" disabled={submittingForm}>
              Create
            </Button>
          </Form.Group>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default CreateStoreForm;
