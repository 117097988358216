import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from 'Layout';
import AuthContextProvider from 'providers/AuthContextProvider';
import AppContextProvider from 'providers/AppContextProvider';
import { PhantomWalletProvider } from 'providers/SolanaWalletProvider';

const App = () => {
  return (
    <>
      <AuthContextProvider>
        <AppContextProvider>
          <PhantomWalletProvider>
            <Layout />
          </PhantomWalletProvider>
        </AppContextProvider>
      </AuthContextProvider>
    </>
  );
};

export default App;
