import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import {
  getSubscriptionCheckoutUrl,
  getCustomerPortalUrl
} from 'helpers/requests/billing';
import { Link } from 'react-router-dom';

const Subscription = ({ loadingBillingAccount, subscribedUntil }) => {
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const [portalUrl, setPortalUrl] = useState('');

  useEffect(() => {
    const fetchUrls = async () => {
      if (subscribedUntil) {
        const r = await getCustomerPortalUrl();
        setPortalUrl(r.response.data.portal_url);
      } else {
        const r = await getSubscriptionCheckoutUrl();
        setCheckoutUrl(r.response.data.checkout_url);
      }
    };
    fetchUrls();
  }, [subscribedUntil]);

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex">
        <div>
          <div>
            Subscription:{' '}
            {loadingBillingAccount ? (
              ''
            ) : (
              <>
                {subscribedUntil ? (
                  <Link to={portalUrl}>View</Link>
                ) : (
                  <Link to={checkoutUrl}>Start ($29/month)</Link>
                )}
              </>
            )}
          </div>
          <small>
            Monthly subscription grants you access to 1 Postgres database, 1
            Qdrant vector store and adds 20 hours to your balance.
          </small>
        </div>
      </Card.Header>
    </Card>
  );
};

export default Subscription;
