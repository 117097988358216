import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { getQdrants } from 'helpers/requests/vector-stores';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Empty from 'components/vector-stores/qdrant/list/Empty';
import Loading from 'components/vector-stores/qdrant/list/Loading';
import getColumns from './get-columns';
import Table from './Table';
import Header from './Header';

const Qdrants = () => {
  const [qdrantList, setQdrantList] = useState([]);
  const [loading, setLoading] = useState(true);

  let handleGetQdrants = async () => {
    const r = await getQdrants();
    if (r.success) {
      setQdrantList(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetQdrants();
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Header />
      </Card.Header>
      <Card.Body className="p-0">
        {loading && <Loading />}
        {!loading && qdrantList.length === 0 && <Empty />}
        {!loading && qdrantList.length > 0 && (
          <Table columns={getColumns()} data={qdrantList} />
        )}
      </Card.Body>
    </Card>
  );
};

export default Qdrants;
