import { ax } from 'helpers/requests/axios';
import { apiPaths } from 'config';

export async function getBillingAccount() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appBilling.accounts.detail);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getCheckoutUrl(balancePurchaseOfferId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appBilling.checkout.url, {
      params: { balance_purchase_offer_id: balancePurchaseOfferId }
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getSubscriptionCheckoutUrl() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appBilling.checkout.subscriptionUrl);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getCustomerPortalUrl() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(
      apiPaths.appBilling.checkout.customerPortalUrl
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getBalancePurchases() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appBilling.balancePurchases.list);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getBalancePurchaseOffers() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(
      apiPaths.appBilling.balancePurchaseOffers.list
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function linkWallet(walletAddress, signature, message) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.post(apiPaths.appBilling.accounts.linkWallet, {
      wallet_address: walletAddress,
      signature: signature,
      message: message
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function unlinkWallet() {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.post(apiPaths.appBilling.accounts.unlinkWallet);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}
