import React, { useState, useEffect } from 'react';
import { getUser } from 'helpers/requests/users';
import { Card, Placeholder, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { appPaths } from 'config';
import { useAppContext } from 'contexts/AppContext';
import SoftBadge from 'components/common/SoftBadge';
import { getThemeDisplayNameFromName } from 'components/common/code-editor/CodeEditor';
import WalletConnection from 'components/settings/profile/WalletConnection';
import { sendEmailVerificationLink } from 'helpers/requests/users';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import { toast } from 'react-toastify';

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [emailVerificationRequested, setEmailVerificationRequested] =
    useState(false);
  const [user, setUser] = useState({});

  const handleGetUser = async () => {
    const r = await getUser();
    if (r.success) {
      setUser(r.response.data);
      setLoading(false);
    }
  };

  const handleSendEmailVerificationLink = async () => {
    const r = await sendEmailVerificationLink();
    if (r.success) {
      toast.success(
        'Email verification link was sent. Please check your inbox.'
      );
      setEmailVerificationRequested(true);
    } else {
      if (r.response.status === 400) {
        toast.error(r.response.data.message);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  const { codeEditorThemeDark, codeEditorThemeLight } = useAppContext();

  useEffect(() => {
    handleGetUser();
  }, []);

  if (loading) {
    return (
      <Card className="mb-3">
        <Card.Header>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={2} />
          </Placeholder>
        </Card.Header>
        <Card.Body className="pt-0">
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={2} />
          </Placeholder>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <div className="d-flex justify-content-between">
            <div>{user.full_name}</div>
            <Button size="sm" as={Link} to={appPaths.settings.updateUser}>
              Edit
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="pt-0">
          <SoftBadge
            bg={user.email_verified ? 'success' : 'danger'}
            className="p-1 me-1"
          >
            {user.email_verified ? 'Verified' : 'Unverified'}
          </SoftBadge>
          <small>{user.email}</small>
          {!user.email_verified && !emailVerificationRequested && (
            <Link
              className="ms-2"
              to="#"
              onClick={e => {
                e.preventDefault();
                handleSendEmailVerificationLink();
              }}
            >
              <small>Send verification link</small>
            </Link>
          )}
        </Card.Body>
      </Card>
      <WalletConnection />
      <Card className="mb-3">
        <Card.Header>
          <div className="d-flex justify-content-between">
            <div>Preferences</div>
            <Button
              size="sm"
              as={Link}
              to={appPaths.settings.updatePreferences}
            >
              Edit
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="pt-0">
          <div>
            Dark theme editor style:
            <SoftBadge bg="info" className="ms-2 p-1">
              {getThemeDisplayNameFromName(codeEditorThemeDark)}
            </SoftBadge>
          </div>
          <div>
            Light theme editor style:
            <SoftBadge bg="info" className="ms-2 p-1">
              {getThemeDisplayNameFromName(codeEditorThemeLight)}
            </SoftBadge>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Profile;
