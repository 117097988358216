import React, { useState, useEffect } from 'react';
import { Row, Card } from 'react-bootstrap';
import { getCheckoutUrl } from 'helpers/requests/billing';
import { toast } from 'react-toastify';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import { getBalancePurchaseOffers } from 'helpers/requests/billing';
import { Link } from 'react-router-dom';

const BalancePurchaseOffers = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleGetBalancePurchaseOffers = async () => {
    const r = await getBalancePurchaseOffers();
    if (r.success) {
      setOffers(r.response.data);
    }
    setLoading(false);
  };

  const handleGetCheckoutUrl = async offerId => {
    toast.info('Please wait.');

    const r = await getCheckoutUrl(offerId);
    if (r.success) {
      window.location.href = r.response.data.checkout_url;
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };

  const generateButtonText = offer => {
    const hourSuffix = offer.hours > 1 ? 's' : '';
    const dollars = (offer.price / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    return `Buy ${offer.hours} hour${hourSuffix} for ${dollars}`;
  };

  useEffect(() => {
    handleGetBalancePurchaseOffers();
  }, []);

  if (loading) return;

  return (
    <>
      {offers.length > 0 && (
        <Card className="mb-3">
          <Card.Header>
            <Row>
              <small>
                If the subscription is not enough, you can always add to your
                balance on-demand:{' '}
                {offers.map(offer => (
                  <Link
                    key={offer.id}
                    to=""
                    onClick={() => handleGetCheckoutUrl(offer.id)}
                  >
                    {generateButtonText(offer)}
                  </Link>
                ))}
              </small>
            </Row>
          </Card.Header>
        </Card>
      )}
    </>
  );
};

export default BalancePurchaseOffers;
