import React from 'react';
import { Link } from 'react-router-dom';
import { appPaths } from 'config';
import { fromISOInUTC } from 'helpers/time';

const getColumns = () => {
  return [
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { id, name } = rowData.row.original;
        return (
          <>
            <Link to={appPaths.vectorStores.qdrant.detail.replace(':id', id)}>
              <strong>{name}</strong>
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'created',
      Header: 'Created',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { created } = rowData.row.original;
        return <>{fromISOInUTC(created).toFormat('LLL dd, HH:mm')}</>;
      }
    }
  ];
};
export default getColumns;
