import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { getPostgreses } from 'helpers/requests/databases';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Empty from 'components/databases/postgres/list/Empty';
import Loading from 'components/databases/postgres/list/Loading';
import getColumns from './get-columns';
import Table from './Table';
import Header from './Header';

const Postgreses = () => {
  const [postgresList, setPostgresList] = useState([]);
  const [loading, setLoading] = useState(true);

  let handleGetPostgreses = async () => {
    const r = await getPostgreses();
    if (r.success) {
      setPostgresList(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetPostgreses();
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Header />
      </Card.Header>
      <Card.Body className="p-0">
        {loading && <Loading />}
        {!loading && postgresList.length === 0 && <Empty />}
        {!loading && postgresList.length > 0 && (
          <Table columns={getColumns()} data={postgresList} />
        )}
      </Card.Body>
    </Card>
  );
};

export default Postgreses;
