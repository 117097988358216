import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import { usePhantomWallet } from 'providers/SolanaWalletProvider';
import {
  getBillingAccount,
  linkWallet,
  unlinkWallet
} from 'helpers/requests/billing';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const WalletConnection = () => {
  const { publicKey, connected, connect, disconnect, wallet } =
    usePhantomWallet();
  const [walletAddress, setWalletAddress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [actionInProgress, setActionInProgress] = useState(false);

  useEffect(() => {
    fetchWalletAddress();
  }, []);

  const fetchWalletAddress = async () => {
    setLoading(true);
    const r = await getBillingAccount();
    if (r.success) {
      setWalletAddress(r.response.data.solana_wallet_address);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  const isWalletLinked = !!walletAddress;

  const handleLinkWallet = async () => {
    if (!connected || !publicKey || !wallet) {
      toast.error('Please connect your wallet first');
      return;
    }

    setActionInProgress(true);

    const message = `Link this Solana wallet to your account: ${publicKey} - ${new Date().toISOString()}`;
    const encodedMessage = new TextEncoder().encode(message);

    // Request the user to sign the message with their wallet
    const signatureData = await wallet.signMessage(encodedMessage, 'utf8');
    // Convert the signature to a base64 string for sending to the server
    const signature = btoa(
      String.fromCharCode.apply(null, signatureData.signature)
    );

    const r = await linkWallet(publicKey, signature, message);
    if (r.success) {
      toast.success('Wallet linked successfully!');
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }

    fetchWalletAddress();
    setActionInProgress(false);
  };

  const handleUnlinkWallet = async () => {
    setActionInProgress(true);

    const r = await unlinkWallet();
    if (r.success) {
      toast.success('Wallet unlinked successfully!');
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }

    fetchWalletAddress();
    setActionInProgress(false);
  };

  if (loading) {
    return (
      <Card className="mb-3">
        <Card.Body>
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="">
          {isWalletLinked && (
            <div className="mb-3">
              <SoftBadge bg="success" className="p-1 me-2">
                Linked wallet: {walletAddress}
              </SoftBadge>

              <div className="mt-2">
                <Button
                  onClick={handleUnlinkWallet}
                  disabled={actionInProgress}
                  variant="outline-danger"
                  size="sm"
                >
                  {actionInProgress ? 'Unlinking...' : 'Unlink Wallet'}
                </Button>
              </div>
            </div>
          )}

          {!isWalletLinked && (
            <>
              {connected && publicKey && (
                <div className="mb-3">
                  <SoftBadge bg="primary" className="p-1 me-2">
                    Connected wallet: {publicKey}
                  </SoftBadge>

                  <div className="mt-2 d-flex">
                    <Button
                      onClick={handleLinkWallet}
                      disabled={actionInProgress}
                      size="sm"
                      className="me-2"
                    >
                      {actionInProgress ? 'Linking...' : 'Link This Wallet'}
                    </Button>

                    <Button
                      onClick={disconnect}
                      size="sm"
                      variant="outline-secondary"
                    >
                      Disconnect
                    </Button>
                  </div>
                </div>
              )}

              {!connected && (
                <div>
                  <Button onClick={connect} size="sm">
                    Connect Phantom Wallet
                  </Button>
                </div>
              )}
            </>
          )}

          <small className="text-muted mt-3 d-block">
            Holding at least 1000 SNAI tokens in your linked wallet will enable
            you to execute AI agents for free.
          </small>
        </div>
      </Card.Body>
    </Card>
  );
};

export default WalletConnection;
