import { ax } from 'helpers/requests/axios';
import { apiPaths } from 'config';

export async function createStore(name, postgres_id) {
  const EXPECTED_STATUS_CODE = 201;
  try {
    const response = await ax.post(apiPaths.appStores.stores.create, {
      name: name,
      postgres_id: postgres_id
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getStore(id) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appStores.stores.detail(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function deleteStore(id) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.delete(apiPaths.appStores.stores.delete(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getStores() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appStores.stores.list);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}
