import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Error404 from 'components/common/Error404';
import { deletePostgres, getPostgres } from 'helpers/requests/databases';
import { appPaths } from 'config';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import {
  Button,
  Card,
  InputGroup,
  FormControl,
  Form,
  Row,
  Col,
  Modal,
  Spinner
} from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Panel from './Panel';

const Postgres = () => {
  const [loading, setLoading] = useState(true);
  const [showDeletingModal, setShowDeletingModal] = useState(false);
  const [postgres, setPostgres] = useState({});
  const [notFound, setNotFound] = useState(false);
  const { id } = useParams();
  const isMounted = useRef(true);
  const navigate = useNavigate();

  const pollPostgres = async id => {
    const result = await getPostgres(id);
    if (!result.success) {
      if (isMounted.current) {
        setShowDeletingModal(false);
        navigate(appPaths.databases.postgres.list);
        toast.success('Database deleted successfully.');
      }
    } else {
      setTimeout(() => pollPostgres(id), 2000);
    }
  };

  let handleGetPostgres = async () => {
    const r = await getPostgres(id);
    if (r.success) {
      setPostgres(r.response.data);
    } else {
      if (r.response.status === 404) {
        setNotFound(true);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setLoading(false);
  };

  const handleDeletePostgres = async () => {
    if (window.confirm('Are you sure you want to delete this database?')) {
      const r = await deletePostgres(id);
      if (r.success) {
        setShowDeletingModal(true);
        pollPostgres(id);
      } else {
        if (r.response.status === 404) {
          toast.error('Database not found. Maybe it was already deleted?');
        } else {
          handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
        }
      }
    }
  };

  useEffect(() => {
    handleGetPostgres();
  }, []);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (notFound) {
    return <Error404 />;
  }

  const getMaskedPassword = password => {
    const visibleCharCount = 8;
    const firstFour = password.substring(0, visibleCharCount);
    const restLen = password.substring(
      visibleCharCount,
      password.length
    ).length;
    return firstFour + '*'.repeat(restLen);
  };

  return (
    <>
      <Panel
        loading={loading}
        postgres={postgres}
        handleDeletePostgres={handleDeletePostgres}
      />
      {!loading && (
        <>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <Form.Label htmlFor="basic-url">Endpoint</Form.Label>
                  <InputGroup className="mb-3">
                    <CopyToClipboard
                      text={postgres.url}
                      onCopy={() => toast.success('Copied to clipboard')}
                    >
                      <Button
                        variant="falcon-primary"
                        size="sm"
                        id="button-addon1"
                      >
                        <FontAwesomeIcon icon="copy" className="me-1" />
                        Copy
                      </Button>
                    </CopyToClipboard>
                    <FormControl
                      aria-label="Example text with button addon"
                      aria-describedby="basic-addon1"
                      value={postgres.url}
                      disabled
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <Form.Label htmlFor="basic-url">Port</Form.Label>
                  <InputGroup className="mb-3">
                    <CopyToClipboard
                      text={postgres.db_port}
                      onCopy={() => toast.success('Copied to clipboard')}
                    >
                      <Button
                        variant="falcon-primary"
                        size="sm"
                        id="button-addon1"
                      >
                        <FontAwesomeIcon icon="copy" className="me-1" />
                        Copy
                      </Button>
                    </CopyToClipboard>
                    <FormControl
                      aria-label="Example text with button addon"
                      aria-describedby="basic-addon1"
                      value={postgres.db_port}
                      disabled
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <Form.Label htmlFor="basic-url">Database</Form.Label>
                  <InputGroup className="mb-3">
                    <CopyToClipboard
                      text={postgres.db_name}
                      onCopy={() => toast.success('Copied to clipboard')}
                    >
                      <Button
                        variant="falcon-primary"
                        size="sm"
                        id="button-addon1"
                      >
                        <FontAwesomeIcon icon="copy" className="me-1" />
                        Copy
                      </Button>
                    </CopyToClipboard>
                    <FormControl
                      aria-label="Example text with button addon"
                      aria-describedby="basic-addon1"
                      value={postgres.db_name}
                      disabled
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <Form.Label htmlFor="basic-url">Username</Form.Label>
                  <InputGroup className="mb-3">
                    <CopyToClipboard
                      text={postgres.db_user}
                      onCopy={() => toast.success('Copied to clipboard')}
                    >
                      <Button
                        variant="falcon-primary"
                        size="sm"
                        id="button-addon1"
                      >
                        <FontAwesomeIcon icon="copy" className="me-1" />
                        Copy
                      </Button>
                    </CopyToClipboard>
                    <FormControl
                      aria-label="Example text with button addon"
                      aria-describedby="basic-addon1"
                      value={postgres.db_user}
                      disabled
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <Form.Label htmlFor="basic-url">Password</Form.Label>
                  <InputGroup className="mb-3">
                    <CopyToClipboard
                      text={postgres.db_password}
                      onCopy={() => toast.success('Copied to clipboard')}
                    >
                      <Button
                        variant="falcon-primary"
                        size="sm"
                        id="button-addon1"
                      >
                        <FontAwesomeIcon icon="copy" className="me-1" />
                        Copy
                      </Button>
                    </CopyToClipboard>
                    <FormControl
                      aria-label="Example text with button addon"
                      aria-describedby="basic-addon1"
                      value={getMaskedPassword(postgres.db_password)}
                      disabled
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
      <Modal
        show={showDeletingModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="text-center">
          <div className="mb-3">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
          <h5>Deleting database...</h5>
          <p className="text-muted">
            This will take under a minute. Please wait.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Postgres;
