export const convertSecondsToReadableTime = seconds => {
  const isNegative = seconds < 0;
  let absSeconds = Math.abs(seconds);
  const hours = Math.floor(absSeconds / 3600);
  absSeconds %= 3600;

  const minutes = Math.floor(absSeconds / 60);
  absSeconds %= 60;

  const remainingSeconds = Math.floor(absSeconds);

  let result = '';

  if (hours > 0) {
    result += `${hours} hour${hours !== 1 ? 's' : ''}`;
  }

  if (result) result += ', ';
  result += `${minutes} minute${minutes !== 1 ? 's' : ''}`;

  if (result) result += ', ';
  result += `${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`;

  if (isNegative) {
    result = '- ' + result;
  }

  return result;
};
