import React from 'react';
import SoftBadge from 'components/common/SoftBadge';
import { Card, Placeholder } from 'react-bootstrap';
import { convertSecondsToReadableTime } from 'components/settings/billing/balance/Helpers';

const Balance = ({ loadingBillingAccount, billingAccount }) => {
  if (loadingBillingAccount) {
    return (
      <Card className="mb-3">
        <Card.Header>
          <Placeholder
            as={Card.Text}
            animation="glow"
            className="d-flex justify-content-between"
          >
            <Placeholder xs={2} />
            <Placeholder xs={1} />
          </Placeholder>
        </Card.Header>
        <Card.Body className="pt-0">
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={5} />
          </Placeholder>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="mb-3">
      <Card.Header>
        <div className="d-flex justify-content-between">
          <div>
            <div>
              Balance: {convertSecondsToReadableTime(billingAccount.balance)}{' '}
            </div>
            <small>Balance is your available execution time for agents.</small>
          </div>
          <div>
            {billingAccount.suspended_for_nonpayment ? (
              <SoftBadge pill bg="danger" className="fs--2">
                Suspended for nonpayment
              </SoftBadge>
            ) : (
              <SoftBadge pill bg="success" className="fs--2">
                Active
              </SoftBadge>
            )}
          </div>
        </div>
      </Card.Header>
    </Card>
  );
};

export default Balance;
