import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react';

const PhantomWalletContext = createContext(null);

export const PhantomWalletProvider = ({ children }) => {
  const [wallet, setWallet] = useState(null);
  const [publicKey, setPublicKey] = useState(null);
  const [connected, setConnected] = useState(false);
  const [connecting, setConnecting] = useState(false);

  // Check if Phantom wallet is available
  useEffect(() => {
    const checkForPhantom = () => {
      const phantom = window.solana;
      if (phantom && phantom.isPhantom) {
        setWallet(phantom);
        if (phantom.isConnected) {
          setConnected(true);
          setPublicKey(phantom.publicKey?.toString());
        }
      }
    };

    if (document.readyState === 'complete') {
      checkForPhantom();
    } else {
      window.addEventListener('load', checkForPhantom);
      return () => window.removeEventListener('load', checkForPhantom);
    }
  }, []);

  // Connect to Phantom wallet
  const connect = useCallback(async () => {
    if (!wallet) {
      window.open('https://phantom.app/', '_blank');
      return;
    }

    try {
      setConnecting(true);
      const response = await wallet.connect();
      setPublicKey(response.publicKey.toString());
      setConnected(true);
    } catch (error) {
      console.error('Error connecting to Phantom wallet:', error);
      throw error; // Re-throw to allow the component to handle it
    } finally {
      setConnecting(false);
    }
  }, [wallet]);

  // Disconnect from Phantom wallet
  const disconnect = useCallback(async () => {
    if (wallet) {
      try {
        await wallet.disconnect();
        setConnected(false);
        setPublicKey(null);
      } catch (error) {
        console.error('Error disconnecting from Phantom wallet:', error);
        throw error; // Re-throw to allow the component to handle it
      }
    }
  }, [wallet]);

  // Provide the entire wallet object and functions through context
  const value = {
    wallet, // This is critical for signMessage functionality
    publicKey,
    connected,
    connecting,
    connect,
    disconnect
  };

  return (
    <PhantomWalletContext.Provider value={value}>
      {children}
    </PhantomWalletContext.Provider>
  );
};

// Custom hook to use the Phantom wallet
export const usePhantomWallet = () => {
  const context = useContext(PhantomWalletContext);
  if (!context) {
    throw new Error(
      'usePhantomWallet must be used within a PhantomWalletProvider'
    );
  }
  return context;
};
