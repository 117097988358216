import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import editing from 'assets/img/illustrations/starter.png';
import { appPaths } from 'config';

const Empty = () => {
  return (
    <Row className="align-items-center justify-content-between mx-0">
      <Col lg={6}>
        <img src={editing} className="img-fluid" alt="" />
      </Col>
      <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-left">
        <h3>You have no databases</h3>
        <p className="lead">Create your first database</p>
        <Link
          className="btn btn-falcon-primary"
          to={appPaths.databases.postgres.create}
        >
          Create
        </Link>
      </Col>
    </Row>
  );
};
export default Empty;
