import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Form,
  Row,
  Col,
  Alert,
  Spinner,
  Modal
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { appPaths } from 'config';
import { createPostgres, getPostgres } from 'helpers/requests/databases';
import { setFormErrors } from 'helpers/errors';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const CreatePostgresForm = () => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const [showCreatingModal, setShowCreatingModal] = useState(false);
  const isMounted = useRef(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm();
  const navigate = useNavigate();

  const pollPostgres = async id => {
    try {
      const result = await getPostgres(id);
      if (result.success) {
        if (isMounted.current) {
          setShowCreatingModal(false);
          navigate(appPaths.databases.postgres.detail.replace(':id', id));
          toast.success('Database created successfully.');
        }
      } else {
        setTimeout(() => pollPostgres(id), 2000); // Poll every 2 seconds
      }
    } catch (error) {
      console.error('Polling error:', error);
      setShowCreatingModal(false);
      toast.error('Error while creating database');
    }
  };

  const submitForm = async data => {
    setSubmittingForm(true);
    const r = await createPostgres(data.name);
    if (r.success) {
      setShowCreatingModal(true);
      pollPostgres(r.response.data.id);
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(submitForm)}>
        <Card className="mb-3 bg-light">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} md={6} sm={12}>
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="name"
                  type="text"
                  isInvalid={!!errors.name}
                  {...register('name', {
                    required: 'Enter name'
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>

        {errors.root?.nonFieldError?.message && (
          <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
        )}

        <Card>
          <Card.Body>
            <Form.Group className="d-flex justify-content-end">
              <Button type="submit" disabled={submittingForm}>
                Create
              </Button>
            </Form.Group>
          </Card.Body>
        </Card>
      </Form>
      <Modal
        show={showCreatingModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="text-center">
          <div className="mb-3">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
          <h5>Creating database...</h5>
          <p className="text-muted">
            This will take under a minute. Please wait.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreatePostgresForm;
